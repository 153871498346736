import {
  Redirect,
  Route,
  HashRouter as Router,
  Switch,
} from "react-router-dom";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

import AddStory from "./AddStory";
import Game from "./Game";
import Home from "./Home";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import StoryWall from "./StoryWall";

const theme = createTheme({
  typography: {
    body1: {
      fontFamily: '"Varta", "Helvetica Neue", sans-serif',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          color: "#1976d2",
          borderColor: "#1976d2",
        },
      },
    },
  },
});

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <React.Suspense fallback={<LinearProgress />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/stories" component={StoryWall} />
              <Route exact path="/add-story" component={AddStory} />
              <Route exact path="/game" component={Game} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </React.Suspense>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
